<template>
  <CModal class="ActionModel" size="lg" :show.sync="Toggle" :title="$t('Offer.ChooseProduct')" :centered="true" @update:show="HandleHideModal()">
    <CCard v-if="Step === 1">
      <CCardHeader>
        <CInput v-model="SearchFilter.Name" placeholder="請輸入商品關鍵字以搜尋商品..." @change="GetList()" class="mb-0" />
      </CCardHeader>
      <CCardBody class="p-0">
        <CDataTable
          :loading="Loading"
          id="ProductList"
          :items="List"
          :fields="Field"
          :noItemsView="noItemsView"
          responsive
          hover
        >
          <template #Checkbox="{item}">
            <td class="text-center">
              <CButton @click="Choose(item.SeriesNum)" color="secondary">
                {{ $t('Global.Choose') }}
              </CButton>
            </td>
          </template>
          <template #Cover="{item}">
            <td>
              <img v-lazy="item.Cover" width="50" height="50" class="img-fluid" alt=""/>
            </td>
          </template>
          <template #Status="{item}">
            <td>
              <CBadge :color="(item.Status === 1 ? 'success' : 'danger')">
                {{ $t('Product.Status.' + item.Status) }}
              </CBadge>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CPagination
          :activePage.sync="Pagination.Page"
          :pages.sync="Pagination.TotalPages"
          @update:activePage="Init()"
        />
      </CCardFooter>
    </CCard>
    <div v-if="Step === 2">
      <CRow>
        <CCol col="12" md="4">
          <img v-lazy="ChooseItem.Cover" class="img-fluid"/>
        </CCol>
        <CCol col="12" md="8">
          <h4 class="font-weight-bold">{{ ChooseItem.Name }}</h4>
          <hr>
          <template v-if="ChooseItem.EnableOption === 1">
            <div v-for="field in ChooseItem.OptionField" :key="field._id">
              <h5 class="h5 mb-3">
                {{ field.FieldName }}
              </h5>
              <div v-if="ChooseItem.Options.length > 0">
                <template v-for="option in ChooseItem.Options">
                  <div v-if="option.FieldID === field._id" :key="option._id" class="custom-control custom-control-inline custom-radio">
                    <input class="custom-control-input" type="radio" :name="`SelectOptions-${field._id}`" :id="option._id" :value="option._id" v-model="SelectData.SelectOptions[field._id]">
                    <label class="custom-control-label" :for="option._id">{{ option.OptionName }}</label>
                  </div>
                </template>
              </div>
            </div>
            <hr>
          </template>
          <p class="text-danger font-weight-bold">{{ typeof ProductActualAmount === 'number' ? `${$store.state.currency}$` : '' }}{{ ProductActualAmount }}</p>
          <CInput type="number" :placeholder="$t('Order.ProductInfo.Quantity')" v-model="SelectData.Quantity">
            <template #append>
              <CButton @click="SelectData.Quantity++" color="dark">
                <i class="fas fa-plus my-0" />
              </CButton>
            </template>
            <template #prepend>
              <CButton @click="SelectData.Quantity--" color="dark">
                <i class="fas fa-minus my-0" />
              </CButton>
            </template>
          </CInput>
          <CButton @click="Confirm()" color="success">
            {{ $t('Global.Confirm') }}{{ $t('Global.Add') }}
          </CButton>
        </CCol>
      </CRow>
      <CElementCover v-if="(Loading === true)" :opacity="0.75" class="py-5">
        <CSpinner color="info" />
      </CElementCover>
    </div>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0"></footer>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ['Single', 'ChooseProductType', 'Toggle', 'ChooseSaleMode'],
  name: "ProductListComponent",
  data() {
    return {
      Step: 1,
      List: [],
      SearchFilter: {
        SelectCategories: [],
        SelectCategoriesName: '',
        SeriesNum: [],
        CustomSeriesNum: [],
        Name: '',
      },
      ShowFilter: 'All',
      FilterModel: false,
      ChooseIndex: [],
      ChooseItem: {
        Amount: {
          Actual: 0
        }
      },
      SelectData: {
        Quantity: 1,
        SelectOptions: {},
        SelectOptionsSource: [],
        SelectOptionsData: '',
        SelectOptionsDataSource: {}
      },
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 6,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'create_nto',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Product/List.NoResults'),
        noItems: this.$t('Product/List.NoItems')
      }
    }
  },
  watch: {
    'SelectData.SelectOptions': {
      deep: true,
      handler (value) {
        if (Array.isArray(this.ChooseItem.OptionData)) { // 確定是否都有選
          const SelectOptionData = this.ChooseItem.OptionData.map((optionData) => {
            return {
              ID: optionData._id,
              Value: Object.values(optionData.Value).join('-'),
              Field: Object.keys(optionData.Value).join('-'),
              Amount: optionData.Amount.Actual,
              Stock: optionData.Stock
            }
          }).find((optionData) => {
            return optionData.Value === Object.values(value).join('-') && optionData.Field === Object.keys(value).join('-')
          })
          if (SelectOptionData) {
            this.ChooseItem.Amount.Actual = SelectOptionData.Amount
            this.ChooseItem.Stock = SelectOptionData.Stock
            this.SelectData.SelectOptionsData = SelectOptionData.ID
            this.SelectData.SelectOptionsDataSource = SelectOptionData
            this.SelectData.SelectOptionsSource = this.ChooseItem.Options.filter(option => {
              return Object.values(value).includes(option._id.toString())
            })
          }
        }
      }
    }
  },
  computed: {
    ProductActualAmount () {
      if (this.ChooseItem.EnableOption === 1) {
        return this.SelectData.SelectOptionsDataSource?.Amount || '請選擇規格'
      } else {
        return this.ChooseItem.Amount?.Actual || 0
      }
    },
    Field () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') },
        { key: 'Status', label: this.$t('Product/List.Status') },
        { key: 'Checkbox', label: '' }
      ]
    }
  },
  mounted() {
    this.Init().catch((err) => {
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order
      }
      if (this.SearchFilter.CustomSeriesNum.length > 0) {
        RequestQuery.customSeriesNum = this.SearchFilter.CustomSeriesNum.join(',')
      }
      if (this.SearchFilter.SeriesNum.length > 0) {
        RequestQuery.seriesNum = this.SearchFilter.SeriesNum.join(',')
      }
      if (this.SearchFilter.Name !== '') {
        RequestQuery.name = this.SearchFilter.Name
      }
      if (this.$route.query.category) {
        RequestQuery.category = this.SearchFilter.SelectCategories.join(',')
      }
      switch (this.ChooseProductType) {
        case 'Gift':
        case 'ExtraItems':
          RequestQuery.taxonomy = this.ChooseProductType
          break
        default:
          RequestQuery.taxonomy = 'Default'
          break
      }
      if (this.ChooseSaleMode) {
        RequestQuery.saleMode = this.ChooseSaleMode.join(',')
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetCategories() {
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/list',
        method: 'get',
      }).then(({data}) => {
        this.CategoryList = data.list.map((category) => {
          return {label: category.Name, value: category.Slug}
        })
      }).catch((err) => {
        throw err
      })
    },
    Choose (seriesNum) {
      this.Step = 2
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/detail',
        method: 'get',
        params: {
          seriesNum,
          taxonomy: this.ChooseProductType || 'Default'
        }
      }).then(async ({data}) => {
        this.Loading = false
        this.ChooseItem = {
          _id: data.detail._id,
          Taxonomy: data.detail.Taxonomy,
          HeaderText: data.detail.HeaderText,
          Stock: data.detail.Stock,
          Amount: data.detail.Amount || {
            Actual: 0
          },
          SeriesNum: data.detail.SeriesNum,
          CustomSeriesNum: data.detail.CustomSeriesNum,
          Name: data.detail.Name,
          EnableStock: data.detail.EnableStock,
          EnableOption: data.detail.EnableOption,
          Brand: data.detail.Brand,
          Cover: data.detail.Cover,
          Options: data.detail.Options,
          OptionField: data.detail.OptionField,
          OptionData: data.detail.OptionData,
          Discount: 1,
          Variable: data.detail.Variable,
          SKU: null
        }
        if (this.ChooseItem.EnableStock === 1 && this.ChooseItem.Stock.SKU) {
          this.ChooseItem.SKU = await this.$store.dispatch('InnerRequest', {
            url: '/product/stock/detail',
            method: 'get',
            params: {
              customSeriesNum: this.ChooseItem.Stock.SKU,
              taxonomy: 'Stock'
            }
          }).then(({data}) => {
            return {
              Name: data.detail.Name,
              Cover: data.detail.Cover,
              SKU: data.detail.CustomSeriesNum,
              Stock: data.detail.Stock
            }
          })
        }
        if (!this.ChooseItem.Variable) this.$set(this.ChooseItem, 'Variable', {})
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Confirm () {
      this.$emit('ChooseProduct', {
        ...this.ChooseItem,
        ...this.SelectData
      })
      this.HandleHideModal()
    },
    HandleHideModal () {
      this.$emit('update:Toggle', false)
    }
  }
}
</script>

